exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-1-copy-2-js": () => import("./../../../src/pages/about-1 copy 2.js" /* webpackChunkName: "component---src-pages-about-1-copy-2-js" */),
  "component---src-pages-about-1-js": () => import("./../../../src/pages/about-1.js" /* webpackChunkName: "component---src-pages-about-1-js" */),
  "component---src-pages-academic-calendar-js": () => import("./../../../src/pages/academic-calendar.js" /* webpackChunkName: "component---src-pages-academic-calendar-js" */),
  "component---src-pages-academic-program-js": () => import("./../../../src/pages/academic-program.js" /* webpackChunkName: "component---src-pages-academic-program-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-clinics-and-laboratories-js": () => import("./../../../src/pages/clinics-and-laboratories.js" /* webpackChunkName: "component---src-pages-clinics-and-laboratories-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-curriculum-js": () => import("./../../../src/pages/curriculum.js" /* webpackChunkName: "component---src-pages-curriculum-js" */),
  "component---src-pages-dean-of-the-college-js": () => import("./../../../src/pages/dean-of-the-college.js" /* webpackChunkName: "component---src-pages-dean-of-the-college-js" */),
  "component---src-pages-facts-and-statistics-js": () => import("./../../../src/pages/facts-and-statistics.js" /* webpackChunkName: "component---src-pages-facts-and-statistics-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-feature-details-js": () => import("./../../../src/pages/feature-details.js" /* webpackChunkName: "component---src-pages-feature-details-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-graduate-students-js": () => import("./../../../src/pages/graduate-students.js" /* webpackChunkName: "component---src-pages-graduate-students-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructions-and-controls-js": () => import("./../../../src/pages/instructions-and-controls.js" /* webpackChunkName: "component---src-pages-instructions-and-controls-js" */),
  "component---src-pages-it-startup-js": () => import("./../../../src/pages/it-startup.js" /* webpackChunkName: "component---src-pages-it-startup-js" */),
  "component---src-pages-latest-news-js": () => import("./../../../src/pages/latestNews.js" /* webpackChunkName: "component---src-pages-latest-news-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-projects-graduation-js": () => import("./../../../src/pages/projects-graduation.js" /* webpackChunkName: "component---src-pages-projects-graduation-js" */),
  "component---src-pages-schedules-js": () => import("./../../../src/pages/schedules.js" /* webpackChunkName: "component---src-pages-schedules-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-student-guide-js": () => import("./../../../src/pages/student-guide.js" /* webpackChunkName: "component---src-pages-student-guide-js" */),
  "component---src-pages-teaching-staff-js": () => import("./../../../src/pages/teaching-staff.js" /* webpackChunkName: "component---src-pages-teaching-staff-js" */),
  "component---src-pages-top-students-js": () => import("./../../../src/pages/top-students.js" /* webpackChunkName: "component---src-pages-top-students-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-branch-template-js": () => import("./../../../src/templates/branch-template.js" /* webpackChunkName: "component---src-templates-branch-template-js" */),
  "component---src-templates-college-news-template-js": () => import("./../../../src/templates/college-news-template.js" /* webpackChunkName: "component---src-templates-college-news-template-js" */),
  "component---src-templates-laboratories-template-js": () => import("./../../../src/templates/laboratories-template.js" /* webpackChunkName: "component---src-templates-laboratories-template-js" */)
}

